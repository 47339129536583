import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { utilityActions } from "../_actions";
import { generateCSV, downloadBlob } from "../_helpers";

import { PageWithAppDrawer2 } from "../_components/main/PageWithAppDrawer2";
import ResponsiveGrid from "../_components/generic/ResponsiveGrid";

import FiltersGrid from "../_components/generic/FiltersGrid";
import { Paper, Button, makeStyles, Grid, Typography } from "@material-ui/core";
import { utilityService } from "../_services";
import moment from "moment";

export default function ClientOpenContractsPage(props) {
  const classes = useStyles();

  return (
    <PageWithAppDrawer2 pageTitle={"RI Open Contracts"}>
      <Paper className={classes.paper}>
        <ClientOpenContracts />
      </Paper>
    </PageWithAppDrawer2>
  );
}

function ClientOpenContracts(props) {
  const dispatch = useDispatch();
  const items = useSelector((state) => state.utility.client_open_vehicles);
  const webUser = useSelector((state) => state.authentication.webUser);
  const clientGroup = webUser.client_group || null;
  const clientsList = clientGroup
    ? clientGroup.clients_base.map((c) => c.clientid)
    : [webUser.client_id];
  const isAdmin = webUser.roleid === 2 ? true : false;
  const isClient = webUser.roleid !== 2 ? true : false;
  const clientId = webUser.client_id;
  const columns = [
    {
      width: 2,
      title: "Client",
      field: "client_base.clientname",
    },
    {
      width: 2,
      title: "VIN",
      field: "vehiclevin",
    },
    {
      width: 2,
      title: "Status",
      field: "title_status.statusname",
    },
    {
      width: 2,
      title: "Last Note",
      field: "note_latest.note",
    },
    {
      width: 2,
      title: "Make",
      field: "make",
    },
    {
      width: 2,
      title: "Model",
      field: "model",
    },
    {
      width: 2,
      title: "Year",
      field: "yr",
    },
    {
      width: 2,
      title: "Lot#",
      field: "clientstocknumber",
    },
    {
      width: 2,
      title: "Mileage",
      field: "cs_mileage",
    },
    {
      width: 2,
      title: "Kilometers",
      field: "cs_km",
    },
    {
      width: 2,
      title: "Color",
      field: "color",
      dataRender: (item) => item.color || "no color",
    },
    {
      width: 2,
      title: "Engine",
      field: "engine",
      dataRender: (item) => item.engine || "no engine",
    },
    {
      width: 2,
      title: "Landed Date",
      field: "crossedborderdate",
    },
    {
      width: 2,
      title: "Recall Status",
      field: "has_recall",
      dataRender: (item) =>
        Number(item.has_recall) === 1 ? "Open Recall" : "None",
    },
    {
      width: 2,
      title: "Dot Sub Date",
      field: "submitteddotdate",
    },
    {
      width: 2,
      title: "Clearance Date",
      field: "vaultreleasedate",
    },
    {
      width: 2,
      title: "Bond Release Date",
      field: "bondreleasedate",
      dataRender: (item) => {
        if (!item.bondreleasedate) {
          return null;
        }
        if (moment(item.bondreleasedate).isBefore(item.vaultreleasedate)) {
          return (
            <b style={{ color: "green" }}>
              {moment(item.bondreleasedate).format("YYYY-MM-DD")}
            </b>
          );
        } else {
          return moment(item.bondreleasedate).format("YYYY-MM-DD");
        }
      },
    },
    {
      width: 2,
      title: "Photos Count",
      field: "vehicle_photos_count",
    },
    {
      width: 2,
      title: "Speedo Image",
      field: "has_speedo_image",
      dataRender: (item) =>
        Number(item.has_speedo_image) === 1 ? "Yes" : "No",
    },
    {
      width: 2,
      title: "Speedo Date",
      field: "images_speedo.dateuploaded",
      dataRender: (item) =>
        item.images_speedo
          ? moment(item.images_speedo).format("MM/DD/YYYY")
          : "",
    },
    {
      width: 2,
      title: "BGK#",
      field: "bkg_entry_load_number",
    },
    {
      width: 2,
      title: "Entry Photo",
      field: "has_entry_photo",
      dataRender: (item) => (Number(item.has_entry_photo) === 1 ? "Yes" : "No"),
    },
    {
      width: 2,
      title: "Entry Photo Date",
      field: "images_entry.dateuploaded",
      dataRender: (item) =>
        item.images_entry ? moment(item.images_entry).format("MM/DD/YYYY") : "",
    },
    {
      width: 2,
      title: "Scanned 7501 Date",
      field: "scanned_7501_date",
    },
  ];

  // console.log('clients',clientGroup.clients_base);
  // console.log('clients list',clientsList);

  // console.log({ isClient, clientGroup });

  const [availableFilters, setAvailableFilters] = React.useState([
    {
      name: "VIN",
      field: "vehiclevin",
      operator: "like",
      value: "",
      type: "text",
      default: "",
    },
    {
      name: "Include Completed",
      field: "titlestatusid",
      operator: "neq",
      value: "6",
      type: "choice",
      choices: [
        {
          key: "6",
          value: "No, exclude completed",
        },
        {
          key: "5",
          value: "Yes, include completed",
        },
      ],
      default: "6",
    },
    {
      name: "Must have Landed Date?",
      field: "confirmedladeddate",
      operator: "notnull",
      value: "",
      type: "choice",
      choices: [
        {
          key: "",
          value: "All",
        },
        {
          key: "1",
          value: "Yes, must be landed",
        },
      ],
      default: "",
    },
    {
      name: "Must have Cross Date?",
      field: "crossedborderdate",
      operator: "notnull",
      value: "",
      type: "choice",
      choices: [
        {
          key: "",
          value: "All",
        },
        {
          key: "1",
          value: "Yes, must be crossed",
        },
      ],
      default: "",
    },
    {
      name: "Landed Start Date",
      field: "confirmedladeddate",
      operator: ">=",
      value: "",
      type: "date",
      default: "",
    },
    {
      name: "Landed End Date",
      field: "confirmedladeddate",
      operator: "<=",
      value: "",
      type: "date",
      default: "",
    },
    {
      name: "Cross Start Date",
      field: "crossedborderdate",
      operator: ">=",
      value: "",
      type: "date",
      default: "",
    },
    {
      name: "Cross End Date",
      field: "crossedborderdate",
      operator: "<=",
      value: "",
      type: "date",
      default: "",
    },
    isAdmin && {
      name: "Client",
      field: "clientid",
      operator: "eq",
      value: "",
      type: "choice",
      useUtility: true,
      utility: "clients_active",
      utilityValue: "clientname",
      utilityOrder: {
        field: "clientname",
        direction: "asc",
      },
      utilityFilters: [
        {
          field: "clientactive",
          operator: "eq",
          value: 2,
        },
      ],
      choices: [],
      default: "",
    },
    {
      name: "Cancelled",
      field: "cancelled",
      operator: "eq",
      value: 0,
      type: "hidden",
      default: "",
    },
    {
      name: "Status",
      field: "titlestatusid",
      operator: "eq",
      value: "",
      type: "choice",
      useUtility: true,
      utility: "title_statuses",
      utilityValue: "statusname",
      choices: [],
      default: "",
    },
    {
      name: "Not Status",
      field: "titlestatusid",
      operator: "neq",
      value: "",
      type: "choice",
      useUtility: true,
      utility: "title_statuses",
      utilityValue: "statusname",
      choices: [],
      default: "",
    },
    isClient &&
      !clientGroup && {
        name: "Client",
        field: "clientid",
        operator: "eq",
        value: clientId,
        type: "hidden",
        default: "",
      },
    isClient &&
      clientGroup && {
        name: "Clients",
        field: "clientid",
        operator: "in",
        value: clientsList.join(","),
        type: "hidden",
        default: "",
      },
  ]);

  const [getData, setGetData] = React.useState(false);

  const [page, setPage] = React.useState(
    items.page ? Number(items.page) - 1 : 0
  );
  const [perPage, setPerPage] = React.useState(
    items.per_page ? Number(items.per_page) : 0
  );
  const [total, setTotal] = React.useState(
    items.total ? Number(items.total) : 0
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    setGetData(true);
  };
  const handleChangeRowsPerPage = (event) => {
    const value = parseInt(event.target.value);
    setPerPage(value);
    setGetData(true);
  };
  useEffect(() => {
    if (getData === true) {
      setGetData(false);
      getNewData();
    }
  }, [getData]);

  useEffect(() => {
    setTotal(items.total ? Number(items.total) : 0);
    setPage(items.page ? Number(items.page) : 0);
    setPerPage(items.per_page ? Number(items.per_page) : 10);
  }, [items]);

  useEffect(() => {
    if (items.items.length === 0) {
      getNewData();
    }
  }, []);

  const getNewData = (download = false) => {
    let newFilters = availableFilters
      .filter((filter) => {
        if ([null, false].includes(filter)) return false;
        if (["", null].includes(filter.value)) return false;
        if (
          ["isnull", "notnull"].includes(filter.operator) &&
          filter.value === false
        )
          return false;
        return true;
      })
      .map((filter) => {
        return {
          field: filter.field,
          operator: filter.operator,
          value: filter.value,
        };
      });

    if (download) {
      let options = {
        filters: newFilters,
        perPage: perPage,
        page: page,
        relations: [
          "images_speedo",
          "images_entry",
          "client_base",
          "title_status",
          "note_latest",
        ],
        appends: ["cs_mileage", "cs_km", "has_speedo_image", "has_entry_photo"],
        params: "withCount[]=images&withCount[]=vehicle_photos",
      };
      // setLoading(true);
      options.page = 1;
      options.perPage = total + 1;
      utilityService
        .getUtility("client_open_vehicles", options)
        .then((values) => {
          const downloadHeaders = columns
            .filter((item) => item.title)
            .map((item) => {
              if (!item.title) return null;
              return item.title;
            });
          const downloadFields = columns
            .filter((item) => item.field)
            .map((item) => {
              if (!item.field) return null;
              return item.field;
            });

          return generateCSV(downloadHeaders, downloadFields, values.data);
        })
        .then((csv) => {
          const blob = new Blob([csv], { type: "text/csv" });
          downloadBlob(blob, `open_contracts.csv`);
        })
        .catch(console.error)
        .finally(() => {
          // setLoading(false);
        });
      return;
    }

    dispatch(
      utilityActions.getUtility("client_open_vehicles", {
        page,
        perPage,
        filters: newFilters,
        relations: [
          "images_speedo",
          "images_entry",
          "client_base",
          "title_status",
          "note_latest",
        ],
        appends: ["cs_mileage", "cs_km", "has_speedo_image", "has_entry_photo"],
        params: "withCount[]=images&withCount[]=vehicle_photos",
        // orders: [{ field: "first_name", direction: "asc" }],
      })
    );
  };

  const handleFilterChange = (newFilter) => {
    // console.log({availableFilters});
    const newFilters = availableFilters.map((filter) => {
      // console.log({ filter });
      if (!filter){
        return null;
      }
      if (filter.field !== newFilter.field) return filter; // not the droid we're looking for, return unchanged
      if (filter.name !== newFilter.name) return filter;
      filter.value = newFilter.value;
      return filter;
    });
    setAvailableFilters(newFilters);
    setPage(1);
    setGetData(true);
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              getNewData();
            }}
          >
            Refresh
          </Button>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              getNewData(true);
            }}
          >
            Download
          </Button>
        </Grid>
      </Grid>

      {clientGroup && (
        <Grid container>
          <Grid xs={12}>
            <Typography>Client Group: {clientGroup.name}</Typography>
          </Grid>
          {clientGroup.clients_base.map((c) => (
            <Grid xs={12} key={c.clientid}>
              <Typography>- {c.clientname}</Typography>
            </Grid>
          ))}
        </Grid>
      )}

      <FiltersGrid
        availableFilters={availableFilters}
        onChange={handleFilterChange}
      />
      <ResponsiveGrid
        loading={items.loading}
        columns={columns}
        data={items.items}
        page={page - 1} // API has a 1 based page count, Material UI has a zero based page count
        perPage={perPage}
        total={total}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));
