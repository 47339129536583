import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

import HomeIcon from "@material-ui/icons/Home";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from "@material-ui/icons/Dashboard";

import SettingsIcon from "@material-ui/icons/Settings";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AssignmentLateIcon from "@material-ui/icons/AssignmentLate";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import GroupIcon from "@material-ui/icons/Group";
import DeveloperModeIcon from "@material-ui/icons/DeveloperMode";

import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import LocalShippingTwoToneIcon from "@material-ui/icons/LocalShippingTwoTone";
import { makeStyles } from "@material-ui/core";

export default function PageMenu(props) {
  const classes = usePageMenuStyles();

  const webUserSelector = useSelector((state) => state.authentication.webUser);
  const userSelector = useSelector((state) => state.authentication.user);
  const roles = userSelector?.roles;

  const notesOpenSelector = useSelector(
    (state) => state.utility.vehicle_notes_open
  );

  const hasAdminRole = !!roles?.admin;
  const hasGstManageRole = !!roles?.gst_manage;
  const hasSvetnaRole = !!roles?.svetna;
  const hasSvetnaBuyerRole = !!roles?.svetna_buyer;
  const hasSvetnaAdminRole = !!roles?.svetna_admin;

  const isAdmin = webUserSelector
    ? Number(webUserSelector.roleid) === 2
      ? true
      : false
    : false;

  let isHudson = false;
  let isHoland = false;
  const clientName =
    webUserSelector && webUserSelector.client
      ? webUserSelector.client.clientname
      : null;
  if (
    clientName !== null &&
    clientName.toLowerCase().includes("larry hudson")
  ) {
    isHudson = true;
  }
  if (
    clientName !== null &&
    clientName.toLowerCase().includes("holand leasing")
  ) {
    isHoland = true;
  }

  const [adminExpand, setAdminExpand] = useState(false);
  const [titlesExpand, setTitlesExpand] = useState(false);
  const [riReportsExpand, setRiReportsExpand] = useState(false);
  const [reportsExpand, setReportsExpand] = useState(false);
  const [accountingExpand, setAccountingExpand] = useState(false);
  const [transportExpand, setTransportExpand] = useState(false);
  const [svetnaExpand, setSvetnaExpand] = useState(true);

  let hasDvsMenu = true;
  if (hasSvetnaBuyerRole) {
    hasDvsMenu = false;
  }

  return (
    <div>
      {hasSvetnaRole && (
        <>
          <ListItem button onClick={() => setSvetnaExpand(!svetnaExpand)}>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Svetna Menu" />
            {svetnaExpand ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={svetnaExpand} timeout="auto" unmountOnExit>
            <ListItem
              component={Link}
              to="/svetna/vehicledashboard"
              button
              className={classes.nested}
            >
              <ListItemIcon>
                <DirectionsCarIcon />
              </ListItemIcon>
              <ListItemText primary="Vehicle Dashboard" />
            </ListItem>
            <ListItem
              component={Link}
              to="/svetna/scan"
              button
              className={classes.nested}
            >
              <ListItemIcon>
                <DeveloperModeIcon />
              </ListItemIcon>
              <ListItemText primary="Svetna Scan" />
            </ListItem>
            <ListItem
              component={Link}
              to="/svetna/dealers"
              button
              className={classes.nested}
            >
              <ListItemIcon>
                <GroupIcon />
              </ListItemIcon>
              <ListItemText primary="Svetna Dealers" />
            </ListItem>
            <ListItem
              component={Link}
              to="/svetna/reports"
              button
              className={classes.nested}
            >
              <ListItemIcon>
                <GroupIcon />
              </ListItemIcon>
              <ListItemText primary="Reports" />
            </ListItem>
            {hasSvetnaAdminRole && (
              <>
                <ListItem
                  component={Link}
                  to="/svetna/users"
                  button
                  className={classes.nested}
                >
                  <ListItemIcon>
                    <GroupIcon />
                  </ListItemIcon>
                  <ListItemText primary="Svetna Users" />
                </ListItem>
              </>
            )}
          </Collapse>
        </>
      )}
      {hasDvsMenu && (
        <>
          <ListItem component={Link} to="/" button>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>
          <ListItem component={Link} to="/dashboard" button>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
          {isAdmin && (
            <>
              <ListItem component={Link} to="/vehicles" button>
                <ListItemIcon>
                  <AssignmentIcon />
                </ListItemIcon>
                <ListItemText primary="Assignments Rec'd" />
              </ListItem>
              {notesOpenSelector.total > 0 && (
                <ListItem
                  component={Link}
                  to="/vehicle_notes/incomplete"
                  button
                >
                  <ListItemIcon>
                    <AssignmentLateIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={`Notes Queue (${notesOpenSelector.total})`}
                    primaryTypographyProps={{ color: "secondary" }}
                  />
                </ListItem>
              )}
              <ListItem component={Link} to="/clientLoads" button>
                <ListItemIcon>
                  <AssignmentIcon />
                </ListItemIcon>
                <ListItemText primary="Client Loads" />
              </ListItem>
              <ListItem component={Link} to="/clientLocations" button>
                <ListItemIcon>
                  <AssignmentIcon />
                </ListItemIcon>
                <ListItemText primary="Client Locations" />
              </ListItem>
              <ListItem component={Link} to="/clientTransporters" button>
                <ListItemIcon>
                  <AssignmentIcon />
                </ListItemIcon>
                <ListItemText primary="Client Transporters" />
              </ListItem>
              <ListItem component={Link} to="/clientDrivers" button>
                <ListItemIcon>
                  <AssignmentIcon />
                </ListItemIcon>
                <ListItemText primary="Client Drivers" />
              </ListItem>
              <ListItem component={Link} to="/invoicebatches" button>
                <ListItemIcon>
                  <MonetizationOnIcon />
                </ListItemIcon>
                <ListItemText primary="Invoice Batches" />
              </ListItem>
              <ListItem component={Link} to="/paperworkbatches" button>
                <ListItemIcon>
                  <AssignmentTurnedInIcon />
                </ListItemIcon>
                <ListItemText primary="Paperwork Batches" />
              </ListItem>

              {/* RI REPORTS */}
              <ListItem
                button
                onClick={() => setRiReportsExpand(!riReportsExpand)}
              >
                <ListItemIcon>
                  <DirectionsCarIcon />
                </ListItemIcon>
                <ListItemText primary="RI Reports" />
                {riReportsExpand ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={riReportsExpand} timeout="auto" unmountOnExit>
                <ListItem
                  component={Link}
                  to="/management/ri-dot-outstanding"
                  button
                  style={{ paddingLeft: "50px" }}
                >
                  <ListItemText primary="DOT Outstanding" />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/management/ri-dot-status"
                  button
                  style={{ paddingLeft: "50px" }}
                >
                  <ListItemText primary="DOT Status" />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/management/ri-needs-applied"
                  button
                  style={{ paddingLeft: "50px" }}
                >
                  <ListItemText primary="RI Needs Applied" />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/management/riUserTraining"
                  button
                  style={{ paddingLeft: "50px" }}
                >
                  <ListItemText primary="NHTSA User Training" />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/management/nhtsaConformityEdit"
                  button
                  style={{ paddingLeft: "50px" }}
                >
                  <ListItemText primary="NHTSA Conformity Editor" />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/management/nhtsaConformityGenerate"
                  button
                  style={{ paddingLeft: "50px" }}
                >
                  <ListItemText primary="NHTSA Conformity Generate" />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/management/ri-customs-submitted-report"
                  button
                  style={{ paddingLeft: "50px" }}
                >
                  <ListItemText primary="Customs Submitted Report" />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/management/ri-nhtsa-audit-report"
                  button
                  style={{ paddingLeft: "50px" }}
                >
                  <ListItemText primary="NHTSA Audit Report" />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/management/ri-nhtsa-ivr"
                  button
                  style={{ paddingLeft: "50px" }}
                >
                  <ListItemText primary="NHTSA IVR Report" />
                </ListItem>
              </Collapse>

              {/* MANAGER REPORTS */}
              <ListItem button onClick={() => setReportsExpand(!reportsExpand)}>
                <ListItemIcon>
                  <DirectionsCarIcon />
                </ListItemIcon>
                <ListItemText primary="Reports" />
                {reportsExpand ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={reportsExpand} timeout="auto" unmountOnExit>
                <ListItem
                  component={Link}
                  to="/clientOpenContracts"
                  button
                  style={{ paddingLeft: "50px" }}
                >
                  <ListItemText primary="RI Open Contracts" />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/clientHudsonPaperwork"
                  button
                  style={{ paddingLeft: "50px" }}
                >
                  <ListItemText primary="CS Paperwork" />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/management/ri-problems"
                  button
                  style={{ paddingLeft: "50px" }}
                >
                  <ListItemText primary="RI Problem Report" />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/management/ri-status"
                  button
                  style={{ paddingLeft: "50px" }}
                >
                  <ListItemText primary="RI Status Report" />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/management/activity"
                  button
                  style={{ paddingLeft: "50px" }}
                >
                  <ListItemText primary="Staff Volume Report" />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/management/autoims"
                  button
                  style={{ paddingLeft: "50px" }}
                >
                  <ListItemText primary="AutoIMS Reconcile" />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/management/report-charges"
                  button
                  style={{ paddingLeft: "50px" }}
                >
                  <ListItemText primary="Charge Breakdown Report" />
                </ListItem>
                {hasGstManageRole && (
                  <ListItem
                    component={Link}
                    to="/management/gst-report"
                    button
                    style={{ paddingLeft: "50px" }}
                  >
                    <ListItemText primary="GST Report (Daily)" />
                  </ListItem>
                )}

                {hasGstManageRole && (
                  <ListItem
                    component={Link}
                    to="/management/gst-submissions"
                    button
                    style={{ paddingLeft: "50px" }}
                  >
                    <ListItemText primary="GST Submissions" />
                  </ListItem>
                )}

                <ListItem
                  component={Link}
                  to="/management/customer-volumes"
                  button
                  style={{ paddingLeft: "50px" }}
                >
                  <ListItemText primary="Customer Volumes" />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/management/customer-stats"
                  button
                  style={{ paddingLeft: "50px" }}
                >
                  <ListItemText primary="Customer stats" />
                </ListItem>
              </Collapse>

              {/* Admin */}
              <ListItem button onClick={() => setAdminExpand(!adminExpand)}>
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Admin" />
                {adminExpand ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={adminExpand} timeout="auto" unmountOnExit>
                <ListItem
                  component={Link}
                  to="/vehicle_notes/all"
                  button
                  style={{ paddingLeft: "50px" }}
                >
                  <ListItemText primary="Notes Queue" />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/management/massUpdate"
                  button
                  style={{ paddingLeft: "50px" }}
                >
                  <ListItemText primary="Mass Update" />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/management/massUpdateBondReleases"
                  button
                  style={{ paddingLeft: "50px" }}
                >
                  <ListItemText primary="Mass Update Bond Releases" />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/admin/auctions"
                  button
                  style={{ paddingLeft: "50px" }}
                >
                  <ListItemText primary="Auctions" />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/admin/clients"
                  button
                  style={{ paddingLeft: "50px" }}
                >
                  <ListItemText primary="Clients" />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/admin/users"
                  button
                  style={{ paddingLeft: "50px" }}
                >
                  <ListItemText primary="Users" />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/admin/titleStatusTypes"
                  button
                  style={{ paddingLeft: "50px" }}
                >
                  <ListItemText primary="Title Status Types" />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/admin/titleApplicationTypes"
                  button
                  style={{ paddingLeft: "50px" }}
                >
                  <ListItemText primary="Title Application Types" />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/admin/rackRates"
                  button
                  style={{ paddingLeft: "50px" }}
                >
                  <ListItemText primary="Rack Rates (NEW)" />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/admin/pdfFormFields"
                  button
                  style={{ paddingLeft: "50px" }}
                >
                  <ListItemText primary="PDF Form Fields" />
                </ListItem>
              </Collapse>
            </>
          )}
          {!isAdmin && (
            <>
              <ListItem component={Link} to="/vehicles" button>
                <ListItemIcon>
                  <AssignmentIcon />
                </ListItemIcon>
                <ListItemText primary="Assignments Rec'd" />
              </ListItem>
              <ListItem component={Link} to="/clientLoads" button>
                <ListItemIcon>
                  <AssignmentIcon />
                </ListItemIcon>
                <ListItemText primary="Loads" />
              </ListItem>
              <ListItem component={Link} to="/clientLocations" button>
                <ListItemIcon>
                  <AssignmentIcon />
                </ListItemIcon>
                <ListItemText primary="Locations" />
              </ListItem>
              <ListItem component={Link} to="/clientTransporters" button>
                <ListItemIcon>
                  <AssignmentIcon />
                </ListItemIcon>
                <ListItemText primary="Transporters" />
              </ListItem>
              <ListItem component={Link} to="/clientDrivers" button>
                <ListItemIcon>
                  <AssignmentIcon />
                </ListItemIcon>
                <ListItemText primary="Drivers" />
              </ListItem>

              {/* CLIENT REPORTS */}
              <ListItem button onClick={() => setReportsExpand(!reportsExpand)}>
                <ListItemIcon>
                  <DirectionsCarIcon />
                </ListItemIcon>
                <ListItemText primary="Reports" />
                {reportsExpand ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={reportsExpand} timeout="auto" unmountOnExit>
                {(isHudson || isHoland) && (
                  <ListItem
                    component={Link}
                    to="/clientOpenContracts"
                    button
                    style={{ paddingLeft: "50px" }}
                  >
                    <ListItemText primary="RI Open Contracts" />
                  </ListItem>
                )}
                {(isHudson || isHoland) && (
                  <ListItem
                    component={Link}
                    to="/clientHudsonPaperwork"
                    button
                    style={{ paddingLeft: "50px" }}
                  >
                    <ListItemText primary="CS Paperwork" />
                  </ListItem>
                )}
                <ListItem
                  component={Link}
                  to="/management/ri-problems"
                  button
                  style={{ paddingLeft: "50px" }}
                >
                  <ListItemText primary="RI Problem Report" />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/management/ri-status"
                  button
                  style={{ paddingLeft: "50px" }}
                >
                  <ListItemText primary="RI Status Report" />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/client-reports/pending-titles"
                  button
                  style={{ paddingLeft: "50px" }}
                >
                  <ListItemText primary="Pending Titles" />
                </ListItem>
                {hasGstManageRole && (
                  <ListItem
                    component={Link}
                    to="/management/gst-submissions"
                    button
                    style={{ paddingLeft: "50px" }}
                  >
                    <ListItemText primary="GST Submissions" />
                  </ListItem>
                )}
              </Collapse>
            </>
          )}
          {/* TITLES */}
          {isAdmin && (
            <>
              <ListItem button onClick={() => setTitlesExpand(!titlesExpand)}>
                <ListItemIcon>
                  <DirectionsCarIcon />
                </ListItemIcon>
                <ListItemText primary="Titles" />
                {titlesExpand ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={titlesExpand} timeout="auto" unmountOnExit>
                <ListItem
                  component={Link}
                  to="/titles/pending"
                  button
                  style={{ paddingLeft: "50px" }}
                >
                  <ListItemText primary="Pending Titles" />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/titles/pendingBMV"
                  button
                  style={{ paddingLeft: "50px" }}
                >
                  <ListItemText primary="Pending BMV" />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/titles/pendingDOT"
                  button
                  style={{ paddingLeft: "50px" }}
                >
                  <ListItemText primary="Pending DOT/BOND Release" />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/titles/vaultVsBond"
                  button
                  style={{ paddingLeft: "50px" }}
                >
                  <ListItemText primary="Vault vs Bond" />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/admin/titlesSent"
                  button
                  style={{ paddingLeft: "50px" }}
                >
                  <ListItemText primary="Titles Sent" />
                </ListItem>
              </Collapse>
            </>
          )}
          {/* ACCOUNTING */}
          {isAdmin && (
            <>
              <ListItem
                button
                onClick={() => setAccountingExpand(!accountingExpand)}
              >
                <ListItemIcon>
                  <MonetizationOnIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Accounting" />
                {accountingExpand ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={accountingExpand} timeout="auto" unmountOnExit>
                <ListItem
                  component={Link}
                  to="/accounting/unbilled-units"
                  button
                  style={{ paddingLeft: "50px" }}
                >
                  <ListItemText primary="Unbilled Units" />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/accounting/invoice-summary"
                  button
                  style={{ paddingLeft: "50px" }}
                >
                  <ListItemText primary="Invoice Summary" />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/accounting/monthly-summary"
                  button
                  style={{ paddingLeft: "50px" }}
                >
                  <ListItemText primary="Monthly Summary" />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/accounting/one-off-invoices"
                  button
                  style={{ paddingLeft: "50px" }}
                >
                  <ListItemText primary="One Off Invoice" />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/accounting/bisbee-reconcile"
                  button
                  style={{ paddingLeft: "50px" }}
                >
                  <ListItemText primary="Bisbee Reconcile" />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/accounting/yearly-tax-report"
                  button
                  style={{ paddingLeft: "50px" }}
                >
                  <ListItemText primary="Yearly Tax Report" />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/accounting/user-time-report"
                  button
                  style={{ paddingLeft: "50px" }}
                >
                  <ListItemText primary="User Time Report" />
                </ListItem>
              </Collapse>
            </>
          )}
          {/* TRANSPORT */}
          {isAdmin && (
            <>
              <ListItem
                button
                onClick={() => setTransportExpand(!transportExpand)}
              >
                <ListItemIcon>
                  <LocalShippingTwoToneIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Transport POC" />
                {transportExpand ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={transportExpand} timeout="auto" unmountOnExit>
                <ListItem
                  component={Link}
                  to="/transportation/transport-list"
                  button
                  style={{ paddingLeft: "50px" }}
                >
                  <ListItemText primary="Transport List" />
                </ListItem>
              </Collapse>
            </>
          )}
          <ListItem component={Link} to="/scanner" button>
            <ListItemIcon>
              <AddAPhotoIcon />
            </ListItemIcon>
            <ListItemText primary="Scanner / Photos" />
          </ListItem>
        </>
      )}

      <ListItem component={Link} to="/login" button>
        <ListItemIcon>
          <ExitToAppIcon />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItem>
    </div>
  );
}

const usePageMenuStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));
